import './bootstrap';
import '../css/app.css';

import {createSSRApp, h} from 'vue';
import {createInertiaApp, router} from '@inertiajs/vue3';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {ZiggyVue} from '../../vendor/tightenco/ziggy/dist/vue.m';
import VueKonva from 'vue-konva';
import VueGtag, {pageview, exception} from "vue-gtag";
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import { VueReCaptcha } from 'vue-recaptcha-v3';

if (import.meta.env.VITE_BUGSNAG_KEY) {
    Bugsnag.start({
        apiKey: import.meta.env.VITE_BUGSNAG_KEY,
        plugins: [new BugsnagPluginVue()],
        appVersion: import.meta.env.VITE_APP_VERSION,
    });
}

const gaConfig = {
    id: "G-796RGR4XZ3",
    params: {
        send_page_view: false,
    },
};

if (import.meta.env.DEV) {
    gaConfig.params.debug_mode = true;
}

createInertiaApp({
    title: (title) => {
        if (title.length) {
            return `${title} - Old Country Store Fabrics`;
        } else {
            return 'Old Country Store Fabrics';
        }
    },
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({el, App, props, plugin}) {
        const app = createSSRApp({render: () => h(App, props)})
            .use(plugin)
            .use(VueKonva)
            .use(ZiggyVue, Ziggy)
            .use(VueReCaptcha, { siteKey: import.meta.env.VITE_RECAPTCHAV3_SITEKEY })
            .use(VueGtag, {
                config: gaConfig,
            });
        if (import.meta.env.VITE_BUGSNAG_KEY) {
            app.use(Bugsnag.getPlugin('vue'));
        }
        // vue3-country-region-select workaround
        app.config.globalProperties.$i18n = null;
        return app.mount(el);
    },
    progress: {
        delay: 350,
        color: '#4B5563',
    },
});

router.on('navigate', (event) => {
    setTimeout(() => {
        const sort = event.detail.page.props.sortOptions?.find((sortOption) => sortOption.current)?.name;
        pageview({
            page_title: document.title.replace(" - Old Country Store Fabrics", ""),
            page_location: event.detail.page.url,
            component: event.detail.page.component,
            version: event.detail.page.version,
            sort: sort,
        });
    }, 100);

    if (import.meta.env.VITE_BUGSNAG_KEY) {
        if (event.detail.page.props.auth.user) {
            Bugsnag.setUser(event.detail.page.props.auth.user.id, event.detail.page.props.auth.user.username, event.detail.page.props.auth.user.name);
        }
        if (event.detail.page.props.cart) {
            Bugsnag.addMetadata("user", "tracking_id", event.detail.page.props.cart.tracking_id);
        }
    }
});

router.on('exception', (event) => {
    exception({
        description: `${event.detail.exception.name}: ${event.detail.exception.message}`,
        fatal: true,
    });
});

window.addEventListener('popstate', (event) => {
    const reload = event?.state?.props?.reload ?? [];
    let removeListener = router.on('navigate', (event) => {
        removeListener();
        router.reload({
            only: ['cart', 'auth', ...reload],
            preserveState: false,
            preserveScroll: true,
            replace: true
        });
    });
});
